import * as React from 'react'

import { Box, Container, Grid, Link, Stack, Typography } from '@mui/material'

import type { TypographyProps } from '@mui/material'

const FooterText = ({
  align = 'justify',
  children,
}: TypographyProps) => (
  <Typography
    align={align}
    component='div'
    variant='caption'
    gutterBottom
  >
    {children}
  </Typography>
)

export const Footer = () => (
  <Box
    sx={{
      bgcolor: 'grey.900',
      color: 'grey.400',
    }}
  >
    <Container
      maxWidth='lg'
      sx={{
        display: 'flex',
        py: 3,
      }}
    >
      <Grid
        container
        spacing={3}
      >
        <Grid
          item
          xs={12}
          md={3}
        >
          <Stack
            spacing={1}
            sx={(theme) => ({
              textAlign: 'center',
              [theme.breakpoints.up('md')]: {
                textAlign: 'left',
              },
            })}
          >
            <Link
              href='/docs/terminos-y-condiciones.pdf'
              target='_blank'
              rel='noopener'
              color='inherit'
              underline='none'
              fontWeight={500}
            >
              Términos y condiciones
            </Link>
            <Link
              href='/docs/politica-de-privacidad.pdf'
              target='_blank'
              rel='noopener'
              color='inherit'
              underline='none'
              fontWeight={500}
            >
              Política de privacidad
            </Link>
            <Link
              href='/denuncias'
              color='inherit'
              underline='none'
              fontWeight={500}
            >
              Canal de denuncias
            </Link>
          </Stack>
        </Grid>
        <Grid
          item
          xs={12}
          md={9}
        >
          <Typography
            variant='body2'
            align='center'
            gutterBottom
          >
            Netastract está respaldado con
            <Link
              href='https://guita.cl'
              target='_blank'
              rel='noopener'
              color='inherit'
              underline='none'
              fontWeight={700}
            >
              <img
                src='https://guita.cl/images/mail-header.png'
                alt='Logo Guita'
                style={{
                  border: 0,
                  marginLeft: '5px',
                  display: 'inline',
                  outline: 'none',
                  textDecoration: 'none',
                  height: '10px',
                  width: 'auto',
                  fontSize: '12px',
                }}
              />
            </Link>
          </Typography>
          <FooterText align='center'>
            &copy; Netastract {new Date().getFullYear()}
          </FooterText>
        </Grid>
      </Grid>
    </Container>
  </Box>
)

export default Footer

import * as React from 'react'

import { NavBar as BaseNavBar, ThemePaletteSwitch } from 'shared/components'

import NavLogo from './nav_logo'

import type { NavBarProps as BaseNavBarProps } from 'shared/components'

type NavBarProps = Omit<BaseNavBarProps, 'navLogo' | 'sx' | 'themePaletteSwitch'> & {
  rootPath?: string
}

const NavBar = ({
  rootPath,
  children,
  ...rest
}: NavBarProps) => (
  <BaseNavBar
    navLogo={(
      <NavLogo
        href={rootPath}
        height='48px'
      />
    )}
    sx={{
      p: 1,
    }}
    themePaletteSwitch={<ThemePaletteSwitch />}
    {...rest}
  >
    {children}
  </BaseNavBar>
)

export default NavBar

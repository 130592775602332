import * as React from 'react'

import { Button } from '@mui/material'

import Footer from '../components/footer'
import NavBar from '../components/nav_bar'

const NavBarButtons = () => (
  <React.Fragment>
    <Button
      href='/blog/'
      variant='text'
      color='inherit'
      size='large'
      sx={{ display: { xs: 'none', sm: 'block' } }}
    >
      Blog
    </Button>
    <Button
      href='/auth/register/'
      variant='contained'
      size='large'
      sx={{ borderRadius: 0, py: 2, px: 3 }}
    >
      Comenzar
    </Button>
  </React.Fragment>
)

type LayoutProps = {
  children: React.ReactNode
}

export const Layout = ({
  children,
}: LayoutProps) => (
  <React.Fragment>
    <NavBar>
      <NavBarButtons />
    </NavBar>
    {children}
    <Footer />
  </React.Fragment>
)

export default Layout
